import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import xor from 'lodash/xor';

import cx from '/src/utilities/cx';
import { isDesktop } from '/src/utilities/device';

import { getTopicPacksAction } from '/src/actions';
import { createGameAction } from '/src/actions/pre_game';
import { useAction, useGameState } from '/src/state';
import { GameMode } from '/src/state/types';

import Button from '/src/components/shared/button';
import Logo from '/src/components/shared/logo';
import IconArrowRight from '/src/components/shared/icons/arrow_right';
import IconArrowLeft from '/src/components/shared/icons/arrow_left';

import Mode from '/src/components/create/mode';
import Topics from '/src/components/create/topics';
import Name from '/src/components/create/name';
import Creating from '/src/components/create/creating';
import Share from '/src/components/create/share';
import Rounds from '/src/components/create/rounds';

import './style.scss';

function CreateContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="cntnr flex-grow flex flex-col items-center mx-6 my-4 pt-6 px-4 pb-2 overflow-y-hidden md:flex-grow-0 md:w-2/3 lg:w-1/2">
      {children}
    </div>
  );
}

function Create() {
  const { gameId, topicPacks, topicPackOwner } = useGameState();
  const getTopicPacks = useAction(getTopicPacksAction);
  const createGame = useAction(createGameAction);

  const [topicPackUids, setTopicPackUids] = useState<string[]>([]);
  const [random, setRandom] = useState(false);
  const [numRounds, setNumRounds] = useState(1);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(gameId ? 4 : 0);
  const [focused, setFocused] = useState(false);
  const [mode, setMode] = useState<GameMode | null>(null);

  useEffect(() => {
    getTopicPacks();
  }, [getTopicPacks]);

  function handleStartGame() {
    setLoading(true);

    createGame(name, topicPackUids, numRounds, mode).catch(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    if (gameId) {
      setStep(4);
    }
  }, [gameId]);

  function handleSetMode(newMode: GameMode) {
    setMode(newMode);
    setStep(1);
  }

  function handleToggleTopicPackUid(uid: string) {
    if (random) {
      setRandom(false);
      setTopicPackUids([uid]);
    } else {
      setTopicPackUids(uids => xor(uids, [uid]));
    }
  }

  function handleWriteOurOwn() {
    setTopicPackUids([]);
    setStep(2);
  }

  function handleRandom(standardUids: string[]) {
    setRandom(true);
    setTopicPackUids(standardUids);
    setStep(1);
  }

  return (
    <div className="flex flex-col h-full bg-color--primary">
      <div className="mt-4 md:mt-8 md:flex-grow">
        <Logo size={isDesktop() ? 'med' : 'tiny'} />
      </div>
      <SwipeableViews
        containerStyle={{ minHeight: 0, flexGrow: 1 }}
        disabled={true}
        index={step}
        onChangeIndex={setStep}
        slideClassName="flex flex-col md:items-center"
        className="flex flex-col flex-grow md:flex-grow-0"
      >
        <CreateContainer>
          <Mode mode={mode} setMode={handleSetMode} />
        </CreateContainer>
        <CreateContainer>
          <Topics
            random={handleRandom}
            toggleTopicPackUid={handleToggleTopicPackUid}
            topicPacks={topicPacks || []}
            topicPackOwner={topicPackOwner}
            topicPackUids={random ? [] : topicPackUids}
            writeOurOwn={handleWriteOurOwn}
          />
        </CreateContainer>
        <CreateContainer>
          <Rounds
            mode={mode}
            numRounds={numRounds}
            setNumRounds={setNumRounds}
          />
        </CreateContainer>
        <CreateContainer>
          {!loading && (
            <Name
              focused={focused}
              loading={loading}
              name={name}
              onBlur={() => setFocused(false)}
              onFocus={() => setFocused(true)}
              onStartGame={handleStartGame}
              setName={setName}
            />
          )}
          {loading && <Creating />}
        </CreateContainer>
        <CreateContainer>
          <Share gameId={gameId} />
        </CreateContainer>
      </SwipeableViews>
      {!focused && (
        <div className="flex justify-between mx-6 mb-6 md:self-center md:w-2/3 lg:w-1/2">
          <div
            className={cx({
              invisible: loading || gameId || step === 0
            })}
          >
            <Button
              name="back"
              onClick={() => setStep(prevStep => prevStep - 1)}
              variant="text-invert"
            >
              <div className="flex items-center">
                <IconArrowLeft white />
                Back
              </div>
            </Button>
          </div>
          <div
            className={cx({
              invisible: loading || gameId || step === 3
            })}
          >
            <Button
              disabled={
                (step === 0 && !mode) ||
                (step === 1 && topicPackUids.length === 0)
              }
              name="next"
              onClick={() => setStep(prevStep => prevStep + 1)}
              variant="text-invert"
            >
              <div className="flex items-center">
                Next
                <IconArrowRight white />
              </div>
            </Button>
          </div>
        </div>
      )}
      <div className="md:flex-grow" />
    </div>
  );
}

export { Create };
export default Create;
