import React from 'react';

import { useAction } from '/src/state';
import { GameMode } from '/src/state/types';
import { openModalAction } from '/src/actions';

import Button from '/src/components/shared/button';
import IconCircleI from '/src/components/shared/icons/circle_i';

interface ModeProps {
  mode: GameMode | null;
  setMode: (mode: GameMode) => void;
}

const ClassicCoachmark = `The original Top Four, the best party game you can play on your phone. Each player takes turns ranking polarizing things from best to worst. When you're not ranking, you're guessing how your friends will respond.`;
const HotSeatCoachmark = `Who knows the person in the hot seat best? In this version, only one player ranks things the entire game. Meanwhile, the rest of the group guesses how they'll respond. Perfect for birthday parties and other celebrations!`;
const TeamsCoachmark = `Can you reach a consensus before locking in? In this version, each player competes on teams to see who knows the group best. It's classic Top Four, with the added fun of debating with your team every turn. Perfect for groups of 6 or more!`;

function Mode({ mode, setMode }: ModeProps) {
  const openModal = useAction(openModalAction);

  return (
    <div className="flex-grow w-full md:w-1/2 lg:w-1/2 flex flex-col items-center space-y-4 md:mb-8 lg:mb-8">
      <h1 className="modal-header color--dark-gray mb-8">
        Choose how you want to play!
      </h1>
      <div className="flex w-full space-x-2">
        <Button
          fullWidth
          name="classic-mode"
          onClick={() => setMode(GameMode.Classic)}
          variant={mode === GameMode.Classic ? 'primary' : 'secondary'}
        >
          CLASSIC
        </Button>
        <Button
          name="classic-help"
          onClick={() => openModal(ClassicCoachmark)}
          variant="icon"
        >
          <IconCircleI />
        </Button>
      </div>
      <div className="flex w-full space-x-2">
        <Button
          fullWidth
          name="hot-seat-mode"
          onClick={() => setMode(GameMode.HotSeat)}
          variant={mode === GameMode.HotSeat ? 'primary' : 'secondary'}
        >
          HOT SEAT
        </Button>
        <Button
          name="hot-seat-help"
          onClick={() => openModal(HotSeatCoachmark)}
          variant="icon"
        >
          <IconCircleI />
        </Button>
      </div>
      <div className="flex w-full space-x-2">
        <Button disabled fullWidth name="team-mode" variant="secondary">
          <div className="flex items-center justify-center">
            Teams <span className="text-xs ml-2">(Someday)</span>
          </div>
        </Button>
        <Button
          name="team-help"
          onClick={() => openModal(TeamsCoachmark)}
          variant="icon"
        >
          <IconCircleI />
        </Button>
      </div>
    </div>
  );
}

export default Mode;
