import React, { useState } from 'react';

import { useAction, useGameState } from '/src/state';
import {
  deactivatePlayerAction,
  endGameAction,
  updateNameAction
} from '/src/actions/in_game';
import { toActivePlayers } from '/src/utilities/players';
import cx from '/src/utilities/cx';

import IconDelete from '/src/components/shared/icons/delete';
import IconPencil from '/src/components/shared/icons/pencil';
import Button from '/src/components/shared/button';
import TextInput from '/src/components/shared/text_input';

type PROPS = {
  editingName: boolean;
  setEditingName: (editing: boolean) => void;
};

function Settings({ editingName, setEditingName }: PROPS) {
  const [name, setName] = useState('');
  const [deactivatingPlayerUid, setDeactivatingPlayerUid] = useState<
    string | null
  >();
  const [ending, setEnding] = useState(false);
  const [focused, setFocused] = useState(false);

  const { game, playerUid } = useGameState();

  const updateName = useAction(updateNameAction);
  const deactivatePlayer = useAction(deactivatePlayerAction);
  const endGame = useAction(endGameAction);

  if (!game) return null;

  const { players } = game;

  const activePlayers = toActivePlayers(players);

  const me = activePlayers.find(({ uid }) => uid === playerUid);
  const otherPlayers = activePlayers.filter(({ uid }) => uid !== playerUid);

  function handleInputRef(input: HTMLInputElement) {
    if (input) {
      input.focus();
    }
  }

  const containerClasses = cx('overflow-y-auto', {
    'flex-grow': !focused,
    'mb-2': focused
  });

  return (
    <form
      autoComplete="off"
      className="flex-grow cntnr flex flex-col px-8 py-4 space-y-2 overflow-hidden"
    >
      {!editingName && (
        <h1 className="modal-header color--dark-gray mb-6">Edit Players</h1>
      )}
      <div className={containerClasses}>
        {deactivatingPlayerUid && (
          <p className="color--dark-gray text-center">
            <span className="block font-bold">Are you sure you want to</span>
            <span className="block font-bold mb-4">
              deactivate{' '}
              {
                activePlayers.find(({ uid }) => uid === deactivatingPlayerUid)
                  ?.name
              }
              ?
            </span>
            <span className="block">They will be removed from</span>
            <span>the game and cannot rejoin.</span>
          </p>
        )}
        {editingName && (
          <TextInput
            getRef={handleInputRef}
            name="player-name"
            onBlur={() => setFocused(false)}
            onChange={setName}
            onFocus={() => setFocused(true)}
            value={name}
          />
        )}
        {ending && (
          <p className="color--dark-gray text-center">
            <span className="block font-bold">Are you sure you want to</span>
            <span className="block font-bold mb-4">end the game?</span>
            <span className="block">This will end the game for</span>
            <span>everyone, and it cannot be restarted.</span>
          </p>
        )}
        {!editingName && !deactivatingPlayerUid && !ending && (
          <>
            {me && (
              <div className="flex items-center">
                <Button
                  name="edit-me"
                  onClick={() => {
                    setName(me.name);
                    setEditingName(true);
                  }}
                  variant="icon"
                >
                  <IconPencil />
                </Button>
                <span className="font-bold color--dark-gray ml-4">
                  {me.name} (You)
                </span>
              </div>
            )}
            {otherPlayers.map(player => (
              <div key={player.uid} className="flex items-center mt-6">
                <Button
                  disabled={activePlayers.length === 2}
                  name="deactivate"
                  onClick={() => setDeactivatingPlayerUid(player.uid)}
                  variant="icon"
                >
                  <IconDelete />
                </Button>
                <span className="font-bold color--dark-gray ml-4">
                  {player.name}
                </span>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="w-60 self-center space-y-4">
        {(deactivatingPlayerUid || ending) && (
          <Button
            fullWidth
            name="cancel"
            onClick={() => {
              setEditingName(false);
              setDeactivatingPlayerUid(null);
              setEnding(false);
            }}
            variant="secondary"
          >
            Cancel
          </Button>
        )}
        {editingName && (
          <Button
            fullWidth
            disabled={name.length === 0}
            name="save-name"
            onClick={() => {
              setFocused(false);
              updateName(name);
              setEditingName(false);
            }}
          >
            Save your name
          </Button>
        )}
        {deactivatingPlayerUid && (
          <Button
            fullWidth
            name="deactivate-confirm"
            onClick={() => {
              deactivatePlayer(deactivatingPlayerUid);
              setDeactivatingPlayerUid(null);
            }}
          >
            Deactivate Player
          </Button>
        )}
        {ending && (
          <Button fullWidth name="end-game-confirm" onClick={() => endGame()}>
            End Game
          </Button>
        )}
        {!editingName && !deactivatingPlayerUid && !ending && (
          <Button fullWidth name="end-game" onClick={() => setEnding(true)}>
            End the game early
          </Button>
        )}
      </div>
    </form>
  );
}

export default Settings;
